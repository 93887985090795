/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Si vols accedir a la{" "}
                    <strong>Policia Local de Santa Coloma de Gramenet 2025</strong>, et presentem el
                    millor curs <strong>ONLINE</strong>.
                </p>

                <p>
                    <strong>iOpos Acadèmia</strong> torna enguany amb un{" "}
                    <strong>campus online encara més complet</strong>.
                </p>

                <p>
                    Un campus que <strong>s’adapta a tu i als teus horaris</strong>.
                </p>

                <p>
                    L’<strong>únic campus</strong> on, des del moment en què et matricules, tens{" "}
                    <strong>accés il·limitat</strong> a tots els continguts, i on s’anirà afegint
                    nou material constantment.
                </p>

                <p>Dins del campus trobaràs:</p>

                <ul>
                    <li>Totes les notícies d’actualitat setmanals i tests per posar-te a prova.</li>
                    <li>Informació sobre el consistori, districtes, la ciutat i curiositats.</li>
                    <li>Cultura general bàsica i avançada, amb vídeos quiz per temàtiques.</li>
                    <li>
                        Els 40 temes del temari oficial, amb idees clau de cada tema i tests
                        d’autoavaluació.
                    </li>
                    <li>8 exàmens oficials de les últimes convocatòries de Sabadell.</li>
                    <li>Tots els premis, esports i personalitats rellevants.</li>
                    <li>Simulacres òmnibus per comprovar el teu nivell.</li>
                </ul>

                <p>
                    I, a més, <strong>classes en directe</strong> cada dimecres de 17.00 a 19.00 h.
                </p>

                <p>
                    Ara entens per què els nostres alumnes obtenen{" "}
                    <strong>les millors notes any rere any</strong>?
                </p>

                <p>
                    I si això et sembla poc… per ser alumne tindràs accés a un{" "}
                    <strong>grup de WhatsApp</strong>, on setmanalment podràs participar en{" "}
                    <strong>Kahoots i reptes de nivell</strong>.
                </p>

                <p>
                    El curs estarà disponible <strong>fins al dia de l’examen</strong>.
                </p>

                <p>
                    <strong>Accedeix ja al curs i forma’t amb garanties!</strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara’t amb el campus online més complet i flexible: accés il·limitat, contingut en creixement i les millors notes com a resultat. Inscriu-t’hi avui!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
